var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-main site-authorization" },
    [
      _c("div", { staticClass: "authorization" }, [
        _vm.isAuthorizationSet
          ? _c(
              "div",
              { staticClass: "pull-left", attrs: { id: "jobListsId" } },
              [
                _c("h3", { staticClass: "headline" }, [
                  _c("i"),
                  _vm._v("\n                授权公司\n                "),
                  _c(
                    "span",
                    {
                      staticClass: "add-authorization-company",
                      on: { click: _vm.addCompany },
                    },
                    [_vm._v("新增授权公司")]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading,
                            expression: "tableLoading",
                          },
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.tableData,
                          border: "",
                          "tooltip-effect": "light myToolsTip",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "targetFirmName",
                            label: "公司",
                            "show-overflow-tooltip": true,
                            align: "center",
                            "min-width": "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "权限范围",
                            align: "center",
                            "min-width": "80",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.isAuthCandidate
                                      ? _c("span", [_vm._v("人才库")])
                                      : _vm._e(),
                                    scope.row.isAuthCandidate &&
                                    scope.row.isAuthJob
                                      ? _c("span", [_vm._v("，")])
                                      : _vm._e(),
                                    scope.row.isAuthJob
                                      ? _c("span", [_vm._v("职位库")])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2537102958
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "remark",
                            label: "备注",
                            "show-overflow-tooltip": true,
                            align: "center",
                            "min-width": "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "created",
                            label: "添加日期",
                            align: "center",
                            "min-width": "50",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "center",
                            "min-width": "50",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "edit-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editCompany(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-edit-outline",
                                        }),
                                        _vm._v("编辑"),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2376777387
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "work-table-footer" },
                  [
                    _c(
                      "el-pagination",
                      {
                        staticClass: "el-pagination-workTable",
                        attrs: {
                          "current-page": _vm.page,
                          "page-sizes": [10, 20, 50],
                          "page-size": _vm.pageSize,
                          layout: "total, sizes, prev, pager, next, slot",
                          total: _vm.totalCount,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      },
                      [
                        _c("span", { staticClass: "pagination-text" }, [
                          _c(
                            "span",
                            [
                              _vm._v("前往"),
                              _c("el-input", {
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.handlePagerJump.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.pagerJump,
                                  callback: function ($$v) {
                                    _vm.pagerJump = $$v
                                  },
                                  expression: "pagerJump",
                                },
                              }),
                              _vm._v("页"),
                            ],
                            1
                          ),
                          _c("span", { on: { click: _vm.handlePagerJump } }, [
                            _vm._v("跳转"),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _c("div", { staticClass: "authorization-disabled pull-left" }, [
              _vm._v("当前用户没有访问授权管理界面的权限。"),
            ]),
        _c("div", { staticClass: "offset-1 nav-right" }, [
          _c("div", { staticClass: "company-members" }, [
            _c("h4", { staticClass: "title" }, [
              _c("a", { attrs: { href: "/#/member" } }, [
                _vm._v(
                  "\n                        公司成员\n                        "
                ),
                _c("span", { staticClass: "number-circle" }, [
                  _vm._v(_vm._s(`(${_vm.memberCount})`)),
                ]),
              ]),
            ]),
            _c(
              "ul",
              { staticClass: "company-members-list" },
              _vm._l(_vm.memberList, function (member, index) {
                return _c(
                  "li",
                  { key: index },
                  [
                    _c("avatar", {
                      staticClass: "ban-recommend-avatar",
                      attrs: {
                        size: "sm",
                        src: member.avatarUrl,
                        userId: member.userId,
                        enableCard: true,
                        enableLink: true,
                        placement: "bottom-end",
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _c("add-company-dialog", {
        ref: "addCompanyDialog",
        on: { "refresh-list": _vm.handleRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }