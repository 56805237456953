var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编辑授权公司" : "新增授权公司",
        visible: _vm.dialogVisible,
        width: "540px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.beforeClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公司", prop: "targetFirmName" } },
            [
              !_vm.isEdit
                ? _c("el-autocomplete", {
                    attrs: { "fetch-suggestions": _vm.queryFirmAsync },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.ruleForm.targetFirmName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "targetFirmName", $$v)
                      },
                      expression: "ruleForm.targetFirmName",
                    },
                  })
                : _c("p", [_vm._v(_vm._s(_vm.ruleForm.targetFirmName))]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "权限范围", prop: "authority" } },
            [
              _c(
                "div",
                { staticClass: "item-wrap" },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "auth-checkbox-group",
                      model: {
                        value: _vm.ruleForm.authority,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "authority", $$v)
                        },
                        expression: "ruleForm.authority",
                      },
                    },
                    [
                      _c("el-checkbox", {
                        attrs: { label: "人才库", name: "authority" },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "职位库", name: "authority" },
                      }),
                    ],
                    1
                  ),
                  _vm.ruleForm.authority.includes("人才库")
                    ? _c(
                        "div",
                        { staticClass: "radio-tip" },
                        [
                          _c("span", [_vm._v("查看人才联系电话：")]),
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.authSeeMobileType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "authSeeMobileType",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.authSeeMobileType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("不限制查看次数"),
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { label: "2" } },
                                [
                                  _vm._v(
                                    "\n              限制\n              "
                                  ),
                                  _c("el-input", {
                                    staticClass: "mobile-times",
                                    attrs: {
                                      size: "small",
                                      type: "text",
                                      placeholder: "0为不能查看",
                                    },
                                    model: {
                                      value: _vm.ruleForm.authSeeMobileTimes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "authSeeMobileTimes",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.authSeeMobileTimes",
                                    },
                                  }),
                                  _vm._v(
                                    "\n              次 每人/每日\n            "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm.ruleForm.authority.indexOf("人才库") > -1
            ? _c(
                "el-form-item",
                { attrs: { label: "佣金分成比例", prop: "proportion" } },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.proportion,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "proportion", _vm._n($$v))
                        },
                        expression: "ruleForm.proportion",
                      },
                    },
                    [
                      _c("i", { attrs: { slot: "suffix" }, slot: "suffix" }, [
                        _vm._v("%"),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 5,
                  placeholder: "请输入100字内的备注信息",
                },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("\n        保存\n      ")]
              ),
              _c("el-button", { on: { click: _vm.cancelHandle } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }