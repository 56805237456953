<template>
    <div class="site-main site-authorization">
        <div class="authorization">
            <div v-if="isAuthorizationSet" id="jobListsId" class="pull-left">
                <h3 class="headline">
                    <i></i>
                    授权公司
                    <!-- <span @click="addCompany"><i class="el-icon-plus"></i>新增授权公司</span> -->
                    <span class="add-authorization-company" @click="addCompany">新增授权公司</span>
                </h3>
                <div class="container">
                    <el-table
                        v-loading="tableLoading"
                        :data="tableData"
                        border
                        style="width: 100%"
                        tooltip-effect="light myToolsTip"
                        >
                        <el-table-column
                            prop="targetFirmName"
                            label="公司"
                            :show-overflow-tooltip="true"
                            align="center"
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            label="权限范围"
                            align="center"
                            min-width="80">
                            <template slot-scope="scope">
                                <span v-if="scope.row.isAuthCandidate">人才库</span>
                                <span v-if="scope.row.isAuthCandidate && scope.row.isAuthJob">，</span>
                                <span v-if="scope.row.isAuthJob">职位库</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="remark"
                            label="备注"
                            :show-overflow-tooltip="true"
                            align="center"
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            prop="created"
                            label="添加日期"
                            align="center"
                            min-width="50">
                        </el-table-column>
                        <el-table-column
                            label="操作"
                            align="center"
                            min-width="50">
                            <template slot-scope="scope">
                                <span class="edit-icon" @click="editCompany(scope.row)"><i class="el-icon-edit-outline"></i>编辑</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="work-table-footer">
                    <el-pagination :current-page="page" :page-sizes="[10, 20, 50]" :page-size="pageSize" class="el-pagination-workTable" layout="total, sizes, prev, pager, next, slot" :total="totalCount" @size-change="handleSizeChange" @current-change="handleCurrentChange">
                        <span class="pagination-text">
                            <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                            <span @click="handlePagerJump">跳转</span>
                        </span>
                    </el-pagination>
                </div>
            </div>
            <div v-else class="authorization-disabled pull-left">当前用户没有访问授权管理界面的权限。</div>
            <div class="offset-1 nav-right">
                <div class="company-members">
                    <h4 class="title">
                        <!-- <a href="/Headhunting/MyCompany.html#/member">
                            公司成员
                            <span class="number-circle">{{`(${memberCount})`}}</span>
                        </a> -->
                        <a href="/#/member">
                            公司成员
                            <span class="number-circle">{{`(${memberCount})`}}</span>
                        </a>
                    </h4>
                    <!-- <ul class="company-members-list">
                        <li v-for="(member, index) in memberList" :key="index">
                            <avatar
                                class="ban-recommend-avatar"
                                size="sm"
                                :src="member.avatarUrl"
                                :userId="member.id"
                                :enableCard="true"
                                :enableLink="true"
                                placement="bottom-end"
                            ></avatar>
                        </li>
                    </ul> -->
                    <ul class="company-members-list">
                        <li v-for="(member, index) in memberList" :key="index">
                            <avatar
                                class="ban-recommend-avatar"
                                size="sm"
                                :src="member.avatarUrl"
                                :userId="member.userId"
                                :enableCard="true"
                                :enableLink="true"
                                placement="bottom-end"
                            ></avatar>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <add-company-dialog ref="addCompanyDialog" @refresh-list="handleRefresh"></add-company-dialog>
    </div>
</template>

<script>
    import { authorization as authorizationUrl, team as teamUrl } from '#/js/config/api.json';
    import AddCompanyDialog from './component/addCompanyDialog.vue';
    import Avatar from '#/component/common/avatar.vue';
    export default {
        name: "authorization",
        components: {
            AddCompanyDialog,
            Avatar
        },
        created(){
        },
        data(){
            return{
                tableLoading: false,
                tableData: [],
                page: 1,
                pageSize: 10,
                pagerJump: 0,
                totalCount: 0,
                memberCount: 0,
                memberList: []
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            canAuthorizeCandidate () {
                return this.$store.state.user.userInfo.canAuthorizeCandidate;
            },
            privilegeCodeList() {
                return this.userInfo.privilegeCodeList || [];
            },
            isAdministrator() {
                return this.userInfo.isAdministrator;
            },
            isAuthorizationSet() {
                return this.privilegeCodeList.includes('AuthorizationSet') || this.isAdministrator;
            },
        },
        created() {
            if(this.isAuthorizationSet) {
                this.getCompanyList();
            }
            this.getFirmMemberList();
        },
        mounted() {
        },
        methods: {
            getCompanyList() {
                this.tableLoading = true;
                _request({
                    method: 'GET',
                    url: authorizationUrl.get_authorization_list,
                    data: {
                        start: (this.page - 1) * this.pageSize,
                        take: this.pageSize
                    }
                }).then(res => {
                    this.totalCount = res.total;
                    this.tableData = res.list;
                }).finally(() =>{
                    this.tableLoading = false;
                })
            },
            // getFirmMemberList() {
            //     _request({
            //         method: 'GET',
            //         url: authorizationUrl.get_firm_member_list,
            //         data: {
            //             location: 0,
            //             start: 0,
            //             take: 10
            //         }
            //     }).then(res =>{
            //         this.memberCount = res.total;
            //         this.memberList = res.list;
            //     })
            // },
            getFirmMemberList() {
                _request({
                    url: teamUrl.get_firm_teams_members,
                    method: 'POST',
                    baseURL: "LbdOpenApi",
                    data: {
                        teamId: "0",
                        location: 0,
                        start: 0,
                        take: 10
                    }
                }).then(res =>{
                    this.memberCount = res.total;
                    this.memberList = res.list;
                })
            },
            handlePagerJump() {
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.totalCount/this.pageSize);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.page = currentPager;
                    this.getCompanyList();
                }
            },
            handleCurrentChange(val){
                this.page = val;
                this.getCompanyList();
            },
            handleSizeChange(val){
                this.page = 1;
                this.pageSize = val;
                this.getCompanyList();
            },
            addCompany() {
                this.$refs.addCompanyDialog.showHandle();
            },
            editCompany(item) {
                this.$refs.addCompanyDialog.showHandle({
                    isEdit: true,
                    policyId: item.policyId
                });
            },
            handleRefresh() {
                this.page = 1;
                this.getCompanyList();
            }
        }
    }
</script>

<style lang="scss" scope>
@media screen and (max-width: 1400px) {
    .site-authorization {
        .authorization {
            .pull-left {
                width: calc(100% - 330px) !important;
            }
            .nav-right {
                width: 310px !important;
            }
        }
    }
}
@media screen and (min-width: 1400px) and (max-width: 1680px) {
    .site-authorization {
        .authorization {
            .pull-left {
                width: calc(100% - 420px) !important;
            }
            .nav-right {
                width: 400px !important;
            }
        }
    }
}
.site-authorization {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    .authorization {
        // height: 100%;
        min-height: calc(100vh - 184px);
        display: flex;
        justify-content: space-between;

        &-disabled {
            font-size: 16px;
            color: #333;
            padding: 20px;
            text-align: center;
        }

        .pull-left {
            width: calc(100% - 490px);
            background-color: #fff;
            border-radius: 8px;

            .headline {
                padding: 0 20px;
                position: relative;
                font-size: 18px;
                color: #333;
                height: 60px;
                line-height: 60px;
                margin: 0 0 22px;
                padding-left: 20px;
                border-bottom: 1px solid #eee;

                // &:before {
                //     content: '';
                //     position: absolute;
                //     top: 2px;
                //     left: 0;
                //     bottom: 2px;
                //     width: 8px;
                //     background-color: $primary;
                // }

                // > span {
                //     float: right;
                //     font-size: 14px;
                //     cursor: pointer;

                //     i {
                //         margin-right:6px;
                //         color: #999;
                //     }
                // }

                .add-authorization-company {
                    float: right;
                    font-size: 14px;
                    cursor: pointer;
                    width: 110px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    background-color: #38BC9D;
                    border-radius: 4px;
                    color: #fff;
                    margin-top: 12px;
                }
            }
            .container {
                padding: 0 20px;
            }
            .work-table-footer {
                margin: 30px 0;
                text-align: center;
                .el-pagination-workTable {
                    float: none;
                }
            }

            .edit-icon {
                cursor: pointer;

                &:hover {
                    color: $primary;
                }
                
                i {
                    color: #999;
                    margin-right: 6px;
                }
            }
        }

        .nav-right {
            width: 470px;
            margin-left: 20px;

            .company-members {
                // padding: 11px 11px 0 18px;
                // border: 1px solid #d3d3d3;
                padding: 20px;
                background-color: #fff;
                border-radius: 8px;

                .title {
                    font-size: 16px;
                    color: #333;
                    margin-bottom: 20px;
                    padding: 0 10px;
                    border-left: 4px solid #38BC9D;

                    .number-circle {
                        color: $primary;
                    }
                }

                &-list {
                    min-height: 100px;

                    li {
                        padding: 10px 5px;
                        display: inline-block;
                        border: none;

                        .avatar-sm {
                            width: 36px;
                            height: 36px;
                        }
                    }
                }
            }
        }
    }

    .el-table {
        thead {
            color: #333 !important;
            th {
                background-color: #E8E8E8 !important;
                &.is-leaf {
                    border-right: 1px solid #ccc;
                }
            }
        }
    }
}
</style>
