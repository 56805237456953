<template>
  <el-dialog
    :title="isEdit ? '编辑授权公司' : '新增授权公司'"
    :visible.sync="dialogVisible"
    width="540px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="beforeClose"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      v-loading="loading"
    >
      <el-form-item label="公司" prop="targetFirmName">
        <!-- <el-input v-model="ruleForm.company"></el-input> -->
        <el-autocomplete
          v-if="!isEdit"
          v-model="ruleForm.targetFirmName"
          :fetch-suggestions="queryFirmAsync"
          @select="handleSelect"
        ></el-autocomplete>
        <p v-else>{{ ruleForm.targetFirmName }}</p>
      </el-form-item>
      <el-form-item label="权限范围" prop="authority">
        <div class="item-wrap">
          <el-checkbox-group
            v-model="ruleForm.authority"
            class="auth-checkbox-group"
          >
            <el-checkbox label="人才库" name="authority"></el-checkbox>
            <el-checkbox label="职位库" name="authority"></el-checkbox>
          </el-checkbox-group>
          <!-- <el-checkbox v-model="checked1" disabled>备选项1</el-checkbox>
            <el-checkbox v-model="checked2" disabled>备选项</el-checkbox> -->
          <div class="radio-tip" v-if="ruleForm.authority.includes('人才库')">
            <span>查看人才联系电话：</span>

            <el-radio-group v-model="ruleForm.authSeeMobileType">
              <el-radio label="1">不限制查看次数</el-radio>
              <el-radio label="2">
                限制
                <el-input
                  size="small"
                  class="mobile-times"
                  type="text"
                  v-model="ruleForm.authSeeMobileTimes"
                  placeholder="0为不能查看"
                />
                次 每人/每日
              </el-radio>
            </el-radio-group>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="佣金分成比例"
        prop="proportion"
        v-if="ruleForm.authority.indexOf('人才库') > -1"
      >
        <el-input v-model.number="ruleForm.proportion">
          <i slot="suffix">%</i>
        </el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          v-model="ruleForm.remark"
          :rows="5"
          placeholder="请输入100字内的备注信息"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">
          保存
        </el-button>
        <el-button @click="cancelHandle">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { authorization as authorizationUrl } from "#/js/config/api.json";
export default {
  name: "add-company-dialog",
  components: {},
  data() {
    return {
      dialogVisible: false,
      isEdit: false,
      loading: false,
      firmList: [],
      ruleForm: {
        policyId: "",
        selectedFirmName: "",
        targetFirmName: "",
        targetFirmId: "",
        proportion: "",
        remark: "",
        authority: [],
        authSeeMobileType: "1",
        authSeeMobileTimes: "0",
      },
      rules: {
        targetFirmName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
          //   { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        // authority: [
        //   {
        //     type: "array",
        //     required: true,
        //     message: "请至少选择一种权限范围",
        //     trigger: "change",
        //   },
        // ],
        proportion: [
          { required: true, message: "佣金分成比例不能为空" },
          { type: "number", message: "佣金分成比例必须为数字值" },
          {
            type: "number",
            min: 0,
            max: 100,
            message: "佣金分成比例范围必须在0-100之间",
          },
        ],
        remark: [
          {
            type: "string",
            min: 0,
            max: 100,
            message: "备注信息必须在100字内",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    showHandle(json) {
      this.dialogVisible = true;

      if (json && json.isEdit) {
        this.isEdit = true;
        this.ruleForm.policyId = json.policyId;
        this.loading = true;
        _request({
          method: "GET",
          url: authorizationUrl.edit_authorization.replace(
            "%p",
            this.ruleForm.policyId
          ),
        })
          .then((res) => {
            let obj = Object.assign({}, this.ruleForm, res);
            if (res.isAuthCandidate) {
              obj.authority.push("人才库");
            }
            if (res.isAuthJob) {
              obj.authority.push("职位库");
            }
            if (res.viewContactCountPerDay == -1) {
              obj.authSeeMobileType = "1";
            } else {
              obj.authSeeMobileType = "2";
              obj.authSeeMobileTimes = res.viewContactCountPerDay;
            }

            this.ruleForm = obj;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    confirmHandle() {},

    beforeClose() {
      this.cancelHandle();
    },

    cancelHandle() {
      this.resetForm("ruleForm");
      this.dialogVisible = false;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.isEdit && !this.ruleForm.targetFirmId) {
            shortTips("请选择正确的公司");
            return false;
          }
          if (
            !this.isEdit &&
            this.ruleForm.selectedFirmName !== this.ruleForm.targetFirmName
          ) {
            shortTips("请选择正确的公司名");
            return false;
          }
          let data = {
            policyId: this.ruleForm.policyId,
            targetFirmName: this.ruleForm.targetFirmName,
            targetFirmId: this.ruleForm.targetFirmId,
            proportion: this.ruleForm.proportion || 0,
            remark: this.ruleForm.remark,
            isAuthCandidate: false,
            isAuthJob: false,
          };
          if (this.ruleForm.authority.indexOf("人才库") > -1) {
            data.isAuthCandidate = true;
            if (this.ruleForm.authSeeMobileType == "1") {
              data.viewContactCountPerDay = -1;
            } else {
              data.viewContactCountPerDay = this.ruleForm.authSeeMobileTimes;
            }
          } else {
            data.proportion = 0;
          }
          if (this.ruleForm.authority.indexOf("职位库") > -1) {
            data.isAuthJob = true;
          }

          this.loading = true;
          _request({
            method: "POST",
            url: !this.isEdit
              ? authorizationUrl.create_authorization
              : authorizationUrl.edit_authorization.replace(
                  "%p",
                  this.ruleForm.policyId
                ),
            data: data,
          })
            .then((res) => {
              this.resetForm("ruleForm");
              this.dialogVisible = false;
              this.$emit("refresh-list");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.isEdit = false;
      this.$refs[formName].resetFields();
      this.ruleForm.policyId = "";
      this.ruleForm.proportion = "";
      this.ruleForm.targetFirmId = "";
      this.ruleForm.selectedFirmName = "";
    },
    queryFirmAsync(queryString, cb) {
      if (!queryString) {
        cb([]);
      } else {
        _request({
          method: "GET",
          url: authorizationUrl.get_firm_list,
          data: {
            fieldType: 1,
            keyword: queryString,
          },
        }).then((res) => {
          res.forEach((item) => {
            item.value = item.name;
          });
          cb(res);
        });
      }
    },
    handleSelect(item) {
      this.ruleForm.targetFirmId = item.id;
      this.ruleForm.selectedFirmName = item.value;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-form-item {
  .el-form-item__label {
    text-align: left;
    white-space: nowrap;
  }

  .el-autocomplete {
    width: 100%;
  }

  .item-wrap {
    display: flex;
    .auth-checkbox-group {
      width: 80px;
      margin-right: 40px;
      .el-checkbox {
        display: block;
        width: 80px;
      }
    }
    .radio-tip {
      flex: 1;

      .mobile-times {
        width: 100px;
      }
    }
  }
}
</style>
